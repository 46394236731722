.para-investidores {
  .glide {
    position: relative;
    //display: flex;
    //align-items: center;
  }

  .glide__slides{
  }

  .glide__track {
    //flex: 1;
    //width: 80%;
  }

  .glide__arrows {
    position: relative;
    padding: 5px;
    background: transparent;
    box-shadow: none;
  }

  .glide__arrow {
    position: relative;
    top: 0;
    margin: 0;
    transform: none;
    left: 0;
    right: 0;
    padding: 5px;
    background-color: transparent;
    border: none;
    outline: none;
    box-shadow: none;
    text-shadow: none;
    transition: all 0.2s ease-in-out;
  }

  .glide__arrow .arrow {
    height: 33px;
    width: 33px;
    opacity: .9;
    transition: all .3s ease;
    background-color: #212121;
    pointer-events: all;

    &--left {
      -webkit-mask: url(../../assets/images/icons/icone-arrow-left-normal.svg) no-repeat center;
      mask: url(../../assets/images/icons/icone-arrow-left-normal.svg) no-repeat center;
      -webkit-mask-size: 33px 33px;
      mask-size: 33px 33px;
    }

    &--right {
      -webkit-mask: url(../../assets/images/icons/icone-arrow-right-normal.svg) no-repeat center;
      mask: url(../../assets/images/icons/icone-arrow-right-normal.svg) no-repeat center;
      -webkit-mask-size: 33px 33px;
      mask-size: 33px 33px;
    }

    &:hover {
      opacity: 1;
    }
  }

  .controller {
    position: absolute;
    top: 50%;
    left: -20px;
    width: 100vw;
    height: 40px;
    transform: translateY(-50%);
    display: flex;
    justify-content: space-between;
    align-items: center;
    pointer-events: none;

    @media (min-width: 768px) {
      left: -50px;
      width: calc(100% + 100px);
    }

  }

  .controller .glide__bullets {
    position: absolute;
    top: 200px;
    left: 50%;
    bottom: initial;
    transform: translateX(-50%);
  }

  .controller .glide__bullets .glide__bullet{
    background-color: #616161;
    opacity: .4;
    margin: 0 16px;
    pointer-events: all;

    &:hover {
      border: 1px solid white;
      background-color: #424242;
      opacity: 1;
    }

    &--active {
      background-color: #000000;
      opacity: 1;
    }
  }
}
